.mat-select-panel mat-option.mat-mdc-option {
  margin: auto 0;
  padding: 10px 20px;
  overflow: visible;
  line-height: 130%;
  word-wrap: break-word;
  white-space: pre-wrap;
  height: 100%;
}

// .mat-option-text.mat-option-text {
//   white-space: normal;
// }
