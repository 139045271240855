.mat-snack-bar-warning .mdc-snackbar__surface {
    background: rgb(255, 248, 115) !important;
    
}

.mat-snack-bar-warning .mdc-snackbar__label {
    color: black !important;
    font-weight: bold !important;
}

.mat-snack-bar-error{
     background-color: $esra-red;

    .mdc-snackbar__surface {
        --mdc-snackbar-container-color: $esra-red;
        --mat-mdc-snack-bar-button-color: rgb(250, 193, 192);
    }
}