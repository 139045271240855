@import "./variables.scss";
@import "./styles/material/checkbox.scss";
@import "./styles/material/material-tab.scss";
@import "./styles/material/material-chip.scss";
@import "./styles/material/radio-button.scss";
@import "./styles/material/material-select.scss";
@import "./styles/material/material-snackbar.scss";
@import "./styles/material/material-card.scss";
@import "./styles/material/material-input.scss";
@import "./styles/buttons.scss";
@import "./styles/ngx-slider.scss";

@import "scss/components/custom/custom-material-library";

/* You can add global styles to this file, and also import other style files */
.left-align {
  float: left;
}

.right-align {
  float: right;
}

.text-left-align {
  text-align: left !important;
}

.text-right-align {
  text-align: right !important;
}

.dialogClose {
  float: right;
  cursor: pointer;
}

.dialogClose div {
  text-align: center;
  margin-top: -6px;
}

.dialogmainheader {
  padding-top: 30px;
}
.dialogmain {
  border: 1px solid gray;
  min-height: 300px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -15px;
}

.button:enabled,
.primary:enabled,
.secondary:enabled {
  border: 1px solid $esra-blue;
}
.button:disabled,
.primary:disabled,
.secondary:disabled {
  border: 1px solid $esra-gray;
  cursor: default;
}

.container-horizontal {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

@mixin container-vertical {
  display: flex;
  flex-direction: column;
}

.required:after {
  content: " *";
  color: red;
}

.pointer {
  cursor: pointer;
}

.progress-bar-container {
  min-height: 6px;
  margin: 2% 3%;

  div {
    height: 5px;
  }
}

.esra-select {
  width: 80%;
}

.width-10 {
  width: 10%;
}

.esra-tooltip {
  white-space: pre-line;
}

.edit-color {
  color: $esra-blue;
}

.delete-color {
  color: $esra-dark-red;
}

.mat-sort-header-arrow {
  opacity: 1 !important;
  transform: none;
  color: black;
}

.wrapped-text {
  padding: 1% 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}
