$esra-blue: #0066b2;
$dark-blue: #0b2d71;
$esra-gray: #e3e3e3;
$esra-highlighted: #e4f4fa;
$esra-medium-gray: #8c8f93;
$esra-dark-grey: #707070;
$esra-black: #000000;
$esra-red: #97002e;
$esra-dark-red: #e21836;
$esra-orange: #e5601f;
$esra-light-orange: #faab18;
$esra-light-blue: #0091a4;
$esra-font-family: "Gotham", Arial, sans-serif;
$esra-font-family-bold: "GothamBold", Arial, sans-serif;
$esra-style-border: 1px solid #ededee;
$color-drop-shadow: rgba(0, 0, 0, 0.16);
