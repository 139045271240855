mat-tab-group {
  .mdc-tab {
    font-weight: bold;
    color: #000000;
  }

  .mdc-tab--active {
    color: $esra-blue;
  }
}

  mat-expansion-panel-header{
    .mat-content {
      flex: 0;
      flex-basis: content;
    }
  }
