:root ngx-slider {
  transition: none;
  margin: 15px 0px;
  height: 40px;
  .ngx-slider-pointer {
    width: 12px;
    height: 12px;
    top: auto;
    bottom: 26px;
    background-color: $esra-blue;
  }
  .ngx-slider-bubble {
    top: auto;
    bottom: 0px;
  }
  .ngx-slider-span {
    color: #000000de;
    font: normal normal medium $esra-font-family;
    font-size: 11px;
  }
  .ngx-slider-pointer:after {
    display: none;
  }
  .ngx-slider-bar {
    height: 2px;
    background: #7d7f86b3;
    top: auto;
    bottom: 7px;
  }
  .ngx-slider-selection {
    background: $esra-blue;
    top: auto;
    bottom: 7px;
  }
}
