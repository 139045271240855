mat-radio-button {
  .mat-mdc-radio-button {
    .mat-radio-outer-circle {
      height: 15px;
      width: 15px;
      border-color: $esra-blue;
    }
  }
  .mat-mdc-radio-button {
    .mat-radio-label-content {
      font-size: 9px;
      padding-left: 0px;
    }
  }
  .mat-mdc-radio-button {
    .mat-radio-label {
      white-space: inherit;
      vertical-align: 0%;
      line-height: initial;
    }
  }
}
