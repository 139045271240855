.dialogButtons {
  padding-top: 10px;
  float: right;
}

.button-blue {
  background-color: $esra-blue;
  border: 1px solid $esra-blue;
  color: #ffffff;

  &:hover {
    background-color: $dark-blue;
    cursor: pointer;
  }
}

.button {
  margin: none;
}

.text-button {
  display: inline-block;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;

  color: $esra-blue;
  font-family: $esra-font-family-bold;

  &:hover {
    color: $dark-blue;
    box-shadow: inset 0 -2px 0 0 $dark-blue;
  }
}

.close-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &::before {
    font-family: "Material Icons";
    content: "close";
  }

  &:hover {
    background-color: $color-drop-shadow;
    color: $dark-blue;
  }
}
